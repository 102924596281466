import { useEffect, useState, useRef } from "react";
import Chart from "chart.js/auto";
import { Link } from "react-router-dom";
import {
  axiosError,
  closeAlert,
  formatDateTime,
  formTypesData,
  preloader,
  _$http,
} from "../../utils";
import { formatDDMMYYYY } from "../../services/_config.services";
import api from "../../services/form.services";
import { formTypesID } from "../../utils/data";
import ApprovalSequenceFlow from "../../components/ApprovalSequenceFlow";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, 
  TooltipProps, } from 'recharts';

function Dashboard() {
  // graph
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const chartRefDeclined = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRefDeclined  = useRef<Chart | null>(null);
  const [formStats, setFormStats] = useState<any>([]);

  const [chartType, setChartType] = useState("bar");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [statusSummary, setStatusSummary] = useState({
    totalRaised: 0,
    totalPending: 0,
    totalApproved: 0,
    totalRejected: 0,
  });
  const [graphlabel, setgraphlabel] = useState([]);
  const [graphdata, setgraphdata] = useState([]);
  const [graphlabelDeclined , setgraphlabelDeclined ] = useState([]);
  const [graphdataDeclined , setgraphdataDeclined ] = useState([]);

  // const [formList, setFormList] = useState(formTypesData);
  const [formSplicer, setFormSplicer] = useState(1);
  const [formID, setFormID] = useState('');
  const [Page] = useState(1);
  const [PageSize] = useState(5);
  const [OrderBy] = useState(2);
  const [SearchString] = useState("");
  const [ApprovalStatus] = useState("");
  const [FormApprovers, setFormApprovers] = useState<any>([]);

  const formList = formTypesData;
  const [formRecord, setFormRecord] = useState<any>([]);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        if (chartInstanceRef.current) chartInstanceRef.current.destroy();

        chartInstanceRef.current = new Chart(ctx, {
          type: chartType === "line" ? "line" : "bar",
          data: {
            labels: graphlabel, // ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: "Forms",
                data: graphdata, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: "#0053e3",
                backgroundColor: "#0053e3",
                // tension: 0.1,
              },
            ],
          },
        });
      }
    }
  }, [graphlabel, graphdata, chartType]);


  useEffect(() => {
    if (chartRefDeclined.current) {
      const ctx = chartRefDeclined.current.getContext("2d");
      if (ctx) {
        if (chartInstanceRefDeclined.current) chartInstanceRefDeclined.current.destroy();

        chartInstanceRefDeclined.current = new Chart(ctx, {
          type: chartType === "line" ? "line" : "bar",
          data: {
            labels: graphlabelDeclined, // ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: "Forms",
                data: graphdataDeclined, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: "#0053e3",
                backgroundColor: "red",
                // tension: 0.1,
              },
            ],
          },
        });
      }
    }
  }, [graphlabelDeclined, graphdataDeclined, chartType]);

  useEffect(() => {
    preloader();
    _$http("GET", "Reports/UserFormStatusTotalSummary")
      .then((res) => {
        closeAlert();
        const record: any = res.data.data;
        setStatusSummary({
          totalRaised: record.totalRaised,
          totalPending: record.totalPending,
          totalApproved: record.totalApproved,
          totalRejected: record.totalRejected,
        });
      })
      .catch((error) => {
        axiosError(error);
      });
  }, []);

  useEffect(() => {
    const date = new Date();
    const todaydate: any = date.toISOString();
    const fullyr: any = date.getFullYear();
    const one_year_ago = new Date(`${fullyr - 1}`).toISOString();

    setStartDate(one_year_ago);
    setEndDate(todaydate);
  }, []);

  useEffect(() => {
    getDashboard(start_date, end_date);
  }, [start_date, end_date]);

  // get form history by form name;
  useEffect(() => {
    fetchFormHistory(
      formSplicer,
      Page,
      PageSize,
      OrderBy,
      SearchString,
      ApprovalStatus
    );
  }, [formSplicer, Page, PageSize, OrderBy, SearchString, ApprovalStatus]);

  useEffect(() => {
    if (formID) {
      getFormById(formID, formList, formSplicer);
    }
  }, [formID, formList, formSplicer]);


  const getDashboard = (start_date: any, end_date: any) => {
    if (start_date !== "" && end_date === "") return;
    if (start_date === "" && end_date !== "") return;
    // if (start_date === "" && end_date === "") return;

    preloader();
    _$http("GET", "Reports/UserFormStatusCount?StartDate="+start_date+"&EndDate="+end_date)
      .then((res) => {
        closeAlert();

        interface DataItem {
          year: number;
          month: number;
          status: string;
          count: number;
      }
        let rawData:DataItem[] = res.data.data;
        let mont = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        const data = res.data.data.filter(
          (item: any) => item.status === "APPROVED"
        );
        const dataDeclined  = res.data.data.filter(
          (item: any) => item.status === "DECLINED"
        );





        const combinedMonthYear: any = [];
        const counts: any = [];

        // Iterate through data and populate arrays
        data.forEach((item: any) => {
          combinedMonthYear.push(`${mont[item.month - 1]}, ${item.year}.`);
          counts.push(item.count);
        });

        const combinedMonthYearDeclined : any = [];
        const countsDeclined : any = [];

        // Iterate through data and populate arrays
        dataDeclined.forEach((item: any) => {
          combinedMonthYearDeclined.push(`${mont[item.month - 1]}, ${item.year}.`);
          countsDeclined.push(item.count);
        });

        setgraphlabel(combinedMonthYear);
        setgraphdata(counts);

        setgraphlabelDeclined (combinedMonthYearDeclined);
        setgraphdataDeclined (countsDeclined);



        interface TransformedData {
            name: string;
            [key: string]: number | string;
        }


      
        const transformedData: TransformedData[] = rawData.reduce((acc: TransformedData[], { month, status, count }) => {

            const monthName = mont[month - 4];
            let monthData = acc.find(item => item.name === monthName);
            if (!monthData) {
                monthData = { name: monthName };
                acc.push(monthData);
            }
            monthData[status] = count;
            return acc;

        }, []);

        setFormStats(transformedData);
        console.log(transformedData)

      })
      .catch((error) => {
        axiosError(error);
      });
  };

  const fetchFormHistory = (
    formTypeId: number,
    Page: number,
    PageSize: number,
    OrderBy: number,
    SearchString: string,
    ApprovalStatus: string
  ) => {
    api
      .fetchFormHistory(
        formTypeId,
        Page,
        PageSize,
        OrderBy,
        SearchString,
        ApprovalStatus
      )
      .then((res) => {
        setFormRecord(res.data);
        if(res?.data?.length > 0)
          setFormID(res?.data[0]?.id)
      });
  };

  const getFormById = (
    formIDParam: string,
    formList: any,
    formSplicer: any
  ) => {
    preloader();
    const controler = formList.filter(
      (item: any) => item.type === Number(formSplicer)
    )[0]?.control;

    _$http("GET", `${controler}/${formIDParam}`)
      .then((res) => {
        closeAlert();
        const record: any = res.data.data;

        if (record) {
          const approval = record.formApprovers.sort(
            (a: any, b: any) => a.step - b.step
          );
          setFormApprovers(approval);
        }
      })
      .catch((error) => {
        axiosError(error);
      });
  };

  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
            fontSize: "10px",
          }}
        >
          <p className="label" style={{ margin: 0 }}>{`${label}`}</p>
          {payload.map((entry, index) => (
            <p
              key={index}
              style={{ margin: 0, color: entry.color }}
            >{`${entry.name}: ${entry.value}`}</p>
          ))}
        </div>
      );
    }
  
    return null;
  };
  

  return (
    <div className="w-full mt-2">
      {/* total count report */}
      <section className="grid grid-cols-1 grid-cols-2 lg:grid-cols-4 gap-3 mb-5">
        <div className="rounded-lg p-5 flex justify-between bg-white shadow">
          <div>
            <h5 className="font-semibold text-gray text-md sm:text-lg">Raised Forms</h5>
            <h1 className="sm:text-3xl text-xl font-extrabold ">
              {statusSummary.totalRaised}
            </h1>
          </div>

          <svg
            className="mt-2"
            width="50"
            height="50"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
            <mask
              id="mask0_264_242"
              maskUnits="userSpaceOnUse"
              x="10"
              y="10"
              width="24"
              height="24"
            >
              <rect
                x="10.5059"
                y="10.5061"
                width="22.9878"
                height="22.9878"
                fill="#7C73AC"
              />
            </mask>
            <g mask="url(#mask0_264_242)">
              <path
                d="M17.2102 26.7891H23.915V24.8735H17.2102V26.7891ZM17.2102 22.9578H26.7884V21.0422H17.2102V22.9578ZM17.2102 19.1265H26.7884V17.2109H17.2102V19.1265ZM15.2946 30.6204C14.7677 30.6204 14.3168 30.4328 13.9416 30.0577C13.5665 29.6825 13.3789 29.2316 13.3789 28.7047V15.2952C13.3789 14.7684 13.5665 14.3174 13.9416 13.9423C14.3168 13.5672 14.7677 13.3796 15.2946 13.3796H28.7041C29.2309 13.3796 29.6819 13.5672 30.057 13.9423C30.4321 14.3174 30.6197 14.7684 30.6197 15.2952V28.7047C30.6197 29.2316 30.4321 29.6825 30.057 30.0577C29.6819 30.4328 29.2309 30.6204 28.7041 30.6204H15.2946Z"
                fill="#0b00ff"
              />
            </g>
          </svg>
        </div>

        <div className="rounded-lg p-5 flex justify-between bg-white shadow hidden">
          <div>
            <h5 className="font-semibold text-gray text-md sm:text-lg">Draft Forms</h5>
            <h1 className="sm:text-3xl text-xl font-extrabold ">
              {statusSummary.totalRaised}
            </h1>
          </div>

          <svg
            className="mt-2"
            width="50"
            height="50"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
            <mask
              id="mask0_264_242"
              maskUnits="userSpaceOnUse"
              x="10"
              y="10"
              width="24"
              height="24"
            >
              <rect
                x="10.5059"
                y="10.5061"
                width="22.9878"
                height="22.9878"
                fill="#7C73AC"
              />
            </mask>
            <g mask="url(#mask0_264_242)">
              <path
                d="M17.2102 26.7891H23.915V24.8735H17.2102V26.7891ZM17.2102 22.9578H26.7884V21.0422H17.2102V22.9578ZM17.2102 19.1265H26.7884V17.2109H17.2102V19.1265ZM15.2946 30.6204C14.7677 30.6204 14.3168 30.4328 13.9416 30.0577C13.5665 29.6825 13.3789 29.2316 13.3789 28.7047V15.2952C13.3789 14.7684 13.5665 14.3174 13.9416 13.9423C14.3168 13.5672 14.7677 13.3796 15.2946 13.3796H28.7041C29.2309 13.3796 29.6819 13.5672 30.057 13.9423C30.4321 14.3174 30.6197 14.7684 30.6197 15.2952V28.7047C30.6197 29.2316 30.4321 29.6825 30.057 30.0577C29.6819 30.4328 29.2309 30.6204 28.7041 30.6204H15.2946Z"
                fill="#000"
              />
            </g>
          </svg>
        </div>

        <div className="rounded-lg p-5 flex justify-between bg-white shadow">
          <div>
            <h5 className="font-semibold text-gray text-md sm:text-lg">Forms Approved</h5>
            <h1 className="sm:text-3xl text-xl font-extrabold">
              {statusSummary.totalApproved}
            </h1>
          </div>
          <svg
            className="mt-2"
            width="50"
            height="50"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
            <mask
              id="mask0_264_247"
              maskUnits="userSpaceOnUse"
              x="10"
              y="10"
              width="24"
              height="24"
            >
              <rect
                x="10.4766"
                y="10.4762"
                width="23.0476"
                height="23.0476"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_264_247)">
              <path
                d="M19.624 27.7621L29.8034 17.5827L28.435 16.2383L19.6 25.0492L15.5427 20.9678L14.1742 22.3363L19.624 27.7621ZM19.624 30.475L11.4854 22.3363L15.5427 18.2549L19.624 22.3363L28.4109 13.5254L32.5403 17.5587L19.624 30.475Z"
                fill="#37a745"
              />
            </g>
          </svg>
        </div>

        <div className="rounded-lg p-5 flex justify-between bg-white shadow">
          <div>
            <h5 className="font-semibold text-gray text-md sm:text-lg">Forms Pending</h5>
            <h1 className="sm:text-3xl text-xl font-extrabold">
              {statusSummary.totalPending}
            </h1>
          </div>
          <svg
            className="mt-2"
            width="50"
            height="50"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
            <mask
              id="mask0_264_252"
              maskUnits="userSpaceOnUse"
              x="10"
              y="10"
              width="24"
              height="24"
            >
              <rect
                x="10.4766"
                y="10.4762"
                width="23.0476"
                height="23.0476"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_264_252)">
              <path
                d="M18.1587 29.6825H25.8412V26.8016C25.8412 25.7452 25.4651 24.8409 24.7128 24.0887C23.9606 23.3364 23.0563 22.9603 21.9999 22.9603C20.9436 22.9603 20.0393 23.3364 19.287 24.0887C18.5348 24.8409 18.1587 25.7452 18.1587 26.8016V29.6825ZM14.3174 31.6032V29.6825H16.238V26.8016C16.238 25.8252 16.4661 24.9089 16.9222 24.0527C17.3784 23.1964 18.0146 22.5121 18.8309 22C18.0146 21.4878 17.3784 20.8036 16.9222 19.9473C16.4661 19.091 16.238 18.1747 16.238 17.1984V14.3174H14.3174V12.3968H29.6825V14.3174H27.7618V17.1984C27.7618 18.1747 27.5338 19.091 27.0776 19.9473C26.6215 20.8036 25.9852 21.4878 25.169 22C25.9852 22.5121 26.6215 23.1964 27.0776 24.0527C27.5338 24.9089 27.7618 25.8252 27.7618 26.8016V29.6825H29.6825V31.6032H14.3174Z"
                fill="#ff9600"
              />
            </g>
          </svg>
        </div>

        <div className="rounded-lg p-5 flex justify-between bg-white shadow">
          <div>
            <h5 className="font-semibold text-gray text-md sm:text-lg">Forms Rejected</h5>
            <h1 className="sm:text-3xl text-xl font-extrabold">
              {statusSummary.totalRejected}
            </h1>
          </div>
          <svg
            className="mt-2"
            width="50"
            height="50"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
            <mask
              id="mask0_264_257"
              maskUnits="userSpaceOnUse"
              x="10"
              y="10"
              width="24"
              height="24"
            >
              <rect
                x="10.4766"
                y="10.4762"
                width="23.0476"
                height="23.0476"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_264_257)">
              <path
                d="M18.5431 26.8014L22.0003 23.3442L25.4574 26.8014L26.8019 25.4569L23.3447 21.9998L26.8019 18.5427L25.4574 17.1982L22.0003 20.6554L18.5431 17.1982L17.1987 18.5427L20.6558 21.9998L17.1987 25.4569L18.5431 26.8014ZM15.2781 30.6427C14.7499 30.6427 14.2977 30.4546 13.9216 30.0785C13.5455 29.7023 13.3574 29.2502 13.3574 28.722V15.2776C13.3574 14.7494 13.5455 14.2973 13.9216 13.9211C14.2977 13.545 14.7499 13.3569 15.2781 13.3569H28.7225C29.2507 13.3569 29.7028 13.545 30.0789 13.9211C30.4551 14.2973 30.6431 14.7494 30.6431 15.2776V28.722C30.6431 29.2502 30.4551 29.7023 30.0789 30.0785C29.7028 30.4546 29.2507 30.6427 28.7225 30.6427H15.2781ZM15.2781 28.722H28.7225V15.2776H15.2781V28.722Z"
                fill="#d91c24"
              />
            </g>
          </svg>
        </div>
      </section>

      {/* dashboard lists - report */}
      <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 mb-5 hidden">
        <div className="rounded-lg bg-white col-span-1 hidden">
          <h3 className="px-4 py-1 text-md leading-9 font-medium">
            Filter Dashboard
          </h3>
          <hr />

          <ul className="py-2">
            <li className="mb-1">
              <Link
                to=""
                className={
                  (["admin-amendment-form"].includes("") ? "active" : "") +
                  " text-sm block py-3 px-4 text-left hover:bg-gray-50"
                }
              >
                Admin Amendment
              </Link>
            </li>
            <li>
              <Link
                to=""
                className={
                  (["contract-approval-form"].includes("") ? "active" : "") +
                  " text-sm block py-3 px-4 text-left hover:bg-gray-50"
                }
              >
                Contract Approval/Admin Amendment for Decentralized Purchasing
              </Link>
            </li>
            <li>
              <Link
                to=""
                className={
                  (["contract-approval-caf-form"].includes("")
                    ? "active"
                    : "") +
                  " text-sm block py-3 px-4 text-left hover:bg-gray-50"
                }
              >
                Contract Approval - CAF
              </Link>
            </li>
            <li>
              <Link
                to=""
                className={
                  (["cpnc-routing-slip-form"].includes("") ? "active" : "") +
                  " text-sm block py-3 px-4 text-left hover:bg-gray-50"
                }
              >
                CPNC Routing Slip
              </Link>
            </li>
            <li>
              <Link
                to=""
                className={
                  (["routing-slip-material-form"].includes("")
                    ? "active"
                    : "") +
                  " text-sm block py-3 px-4 text-left hover:bg-gray-50"
                }
              >
                Routing Slip for Material Purchase
              </Link>
            </li>
            <li>
              <Link
                to=""
                className={
                  (["routing-slip-deviation-form"].includes("")
                    ? "active"
                    : "") +
                  " text-sm block py-3 px-4 text-left hover:bg-gray-50"
                }
              >
                Routing Slip for Deviation from Standard Template
              </Link>
            </li>
            <li>
              <Link
                to=""
                className={
                  (["routing-slip-tsa-form"].includes("") ? "active" : "") +
                  " text-sm block py-3 px-4 text-left hover:bg-gray-50"
                }
              >
                Routing Slip for TSA Contracts
              </Link>
            </li>
            <li>
              <Link
                to=""
                className={
                  (["tucn-saving-sheet-form"].includes("") ? "active" : "") +
                  " text-sm block py-3 px-4 text-left hover:bg-gray-50"
                }
              >
                TUCN Savings Sheet
              </Link>
            </li>
          </ul>
        </div>

        <div className="rounded-lg p-4 bg-white col-span-full hidden">
          {/* <div className=''> */}
          {/* <div className='p-5 rounded-lg bg-white'> */}
          <div className="flex justify-between mb-5">
            <div>
              <h2 className="text-xl font-semibold">
                Approve Orders{" "}
                <small className="font-normal text-xs">
                  (From {formatDDMMYYYY(start_date)} to{" "}
                  {formatDDMMYYYY(end_date)})
                </small>
              </h2>
            </div>

            <div className="flex gap-4">
              <input
                type="date"
                name="ariba_closingdate"
                className="border px-3 py-2 text-xs mr-1"
                value={start_date}
                onChange={(e: any) => setStartDate(e.target.value)}
              />
              <input
                type="date"
                name="ariba_closingdate"
                className="border px-3 py-2 text-xs mr-1"
                value={end_date}
                onChange={(e: any) => setEndDate(e.target.value)}
              />
              <select
                className="border p-1 text-xs"
                onChange={(e) => setChartType(e.target.value)}
              >
                <option value="bar">Bar Chart</option>
                <option value="line">Line Graph</option>
              </select>
            </div>
          </div>
        </div>
      </section>


      <section className="flex w-full mb-5">
        <div className="w-full border bg-white shadow rounded">
          <p className="w-full barchart-title text-xs">
            Status counts by months
          </p>
          <div className="p-4">
            <ResponsiveContainer width="100%" height={330}>
            <BarChart
                data={formStats}
                margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="PENDING" fill="#216ba5" radius={[10, 10, 0, 0]} />
                <Bar dataKey="RESTART" fill="#2563eb" radius={[10, 10, 0, 0]} />
                <Bar dataKey="APPROVED" fill="#008000" radius={[10, 10, 0, 0]} />
                <Bar dataKey="DRAFT" fill="#686868" radius={[10, 10, 0, 0]} />
                <Bar dataKey="CLARIFY" fill="#ca8a04" radius={[10, 10, 0, 0]} />
                <Bar dataKey="DECLINED" fill="#FF0000" radius={[10, 10, 0, 0]} />
                <Bar dataKey="CLOSED" fill="#6c2121" radius={[10, 10, 0, 0]} />

                
                
            </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </section>


      <section className="justify-between mb-4 gap-4 flex hidden">
        <div className="w-full sm:w-1/2 mb-3 gap-4 bg-white p-5 border shadow-lg">
          <div>
            <h2 className="font-semibold">Approved Forms/Month</h2>
            <canvas
              className="mt-12"
              id="mycanvas"
              ref={chartRef}
              width={400}
              height={180}
            ></canvas>
          </div>
        </div>
        
        <div className="w-full lg:w-1/2 mb-3 gap-4 bg-white p-5 border shadow-lg">
          <div>
            <h2 className="font-semibold">Declined Forms/Month</h2>
            <canvas
              className="mt-12"
              id="mycanvas"
              ref={chartRefDeclined}
              width={400}
              height={180}
            ></canvas>
          </div>
        </div>
      </section>

      {/* Record area */}
      <section className="justify-between mb-3 gap-4 bg-gray-50 p-3 shadow flex">
        <div className="mb-3 gap-4 w-full sm:12/12">
          <div className="flex justify-between mb-4 gap-4 bg-white">
            <select
              className="border px-3 py-3 hover:shadow text-sm w-full"
              onChange={(e: any) => setFormSplicer(e.target.value)}
              value={formSplicer}
            >
              {formList.map((item: any) => {
                return (
                  <option key={item?.type} value={item?.type}>
                    {item?.name}
                  </option>
                );
              })}
            </select>

            <div className="flex justify-between hidden">
              <div className="flex gap-2 font-semibold">
                <span>From: </span>
                <input
                  type="date"
                  name="ariba_closingdate"
                  className="border px-3 py-2 text-xs mr-1"
                  value={start_date}
                  onChange={(e: any) => setStartDate(e.target.value)}
                />

                <span>To: </span>
                <input
                  type="date"
                  name="ariba_closingdate"
                  className="border px-3 py-2 text-xs mr-1"
                  value={end_date}
                  onChange={(e: any) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="gap-4 bg-white sm:py-6 sm:px-4 px-2 py-2 border sm:w-full w-[350px]">
            <select
              className="border px-1 py-1 hover:shadow text-xs mb-2 rounded-[3px] w-full"
              onChange={(e: any) => setFormID(e.target.value)}
              value={formID}
            >
              {formRecord?.map((item: any) => {
                return (
                  <option key={item?.id} value={item?.id}>
                    {item?.formID} {"  -  "}
                    {item?.contractTitle}
                  </option>
                );
              })}
            </select>

            <div className="">
              <ApprovalSequenceFlow data={FormApprovers} />
            </div>
          </div>
        </div>

        <div className="mb-3 gap-4 bg-white p-4 border sm:w-12/12 w-full">
          <h2 className="font-semibold">Recent Form Activities</h2>
          <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className=" text-[10px] text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-1 py-2">
                  Form ID
                </th>
                <th scope="col" className="px-1 py-2">
                  Status
                </th>
                <th scope="col" className="px-6 py-2">
                  Date Initiated
                </th>
                <th scope="col" className="px-1 py-2">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {formRecord?.map((item: any, index: number) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-t hover:bg-gray-5 text-[11px] dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                  >
                    <td className="px-1 py-2">{item.formID}</td>
                    <td className="px-1 py-2">{item.currentStatus}</td>
                    <td className="px-6 py-2">
                      {formatDateTime(item.createdDate)}
                    </td>
                    <td className="px-1 py-1 flex">
                      <Link
                        to={`/initiator/forms/details/${
                          formTypesID.filter(
                            (item: any) => item.type === Number(formSplicer)
                          )[0]?.name
                        }/${item.id}`}
                        className="text-blue-600 flex gap-1"
                      >
                        <span>View</span>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
