// * path: /forms/raisedforms/:ID
// * Remove ANY type


import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { axiosError, closeAlert, convertCamelCaseToWords, formatDateTime, preloader, _$http } from '../../utils';
import { FaAngleLeft, FaAngleRight, FaListUl, FaMagnifyingGlass } from "react-icons/fa6";
import { Eye, Repeat, XMark } from '../../components/Icons';
import { formTypesID } from '../../utils/data';
import api from '../../services/form.services';
import SettingsHeader from '../../layouts/admin/SettingsHeader';
import { LabelXS } from '../../components/FormUI';
import { Autocomplete, createFilterOptions } from '@mui/material';
import TextField from "@mui/material/TextField";
import { OptionProps } from "../../interfaces/forms.interface";
import { tinyAlert } from '../../services/_config.services';
import { GrDocumentCsv } from "react-icons/gr";
import {ExcelFile, ExcelSheet, ExcelColumn} from 'react-xlsx-wrapper';

const filterOptions = createFilterOptions({ limit: 100 });
const getOptionLabel = (option: any) => ( <div dangerouslySetInnerHTML={{ __html: !!option.label ? option?.label  : ''  }}/> );


function RaisedForm() {
  var param = useParams();

  const [toggleFilterModal, setToggleFilterModal] = useState(false)
  const [toggleCreateModal, setToggleCreateModal] = useState(false)
  const formTypeParam: string = !!param.formType ? param.formType : '';
  const formTypeId: any = (formTypesID.find((x: any) => x.name === formTypeParam))?.type;

  const [formRecord, setFormRecord] = useState([]);
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [OrderBy] = useState(2);
  const [SearchString, setSearchString] = useState('');
  const [ApprovalStatus, setApprovalStatus] = useState('');
  const [DepartmentID, setDepartmentID] = useState('');
  const [ReassignEmailAddress, setReassignEmailAddress] = useState('');
  const [TotalCount, setTotalCount] = useState(0);
  const [PageIndex, setPageIndex] = useState(0);
  const [HasNextPage, setHasNextPage] = useState(false);
  const [HasPreviousPage, setHasPreviousPage] = useState(false);
  const [FormApprovers, setFormApprovers] = useState<any>({});
  const [FormAlternativeEmail, setFormAlternativeEmail] = useState<any>({});
  const [tepng_users, setTepngUsers] = useState<OptionProps[]>([]);
  const [department, setDepartment] = useState<OptionProps[]>([]);
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [keysArray, setkeysArray] = useState<any[]>([]);
  const [activeKeysArray, setActiveKeysArray] = useState<any[]>([
    'formID',
    'contractTitle',
    'currentStatus',
    'contractorsName',
    'createdDate',
  ]);

  useEffect(() => {
      api.fetchTepngUsers().then((res) => setTepngUsers(res));
      api.fetchDepartment().then((res) => setDepartment(res));
  }, []);


  useEffect(() => {
    setActiveKeysArray([
      'formID',
      'contractTitle',
      'currentStatus',
      'contractorsName',
      'createdDate',
    ])

    fetchFormHistory(formTypeId, Page, PageSize, OrderBy, SearchString, ApprovalStatus, DepartmentID);
    // eslint-disable-next-line
  }, [formTypeId, Page, PageSize, OrderBy, SearchString, ApprovalStatus, DepartmentID, StartDate, EndDate]);

  const fetchFormHistory = (formTypeId: number, Page: number, PageSize: number, OrderBy: number, SearchString: string, ApprovalStatus: string, DepartmentID: string) => {
    if (!!SearchString && SearchString.length < 3) {
      return;
    }
    
    api.fetchFormHistory(formTypeId, Page, PageSize, OrderBy, SearchString, ApprovalStatus, DepartmentID, StartDate, EndDate, true)
      .then(res => {
        
        const keysArray = Object.keys(res.data[0]);
        console.log(keysArray)
        setkeysArray(keysArray)

        setFormRecord(res.data); 

        const data = res.data;

        // Iterate through each object in the array
        const newData = data.map((item:any) => {
          // Clone the original item
          const newItem = Object.assign({}, item);

          // Iterate through each property of the object
          Object.keys(newItem).forEach(key => {
            // If the key contains "Date" and the value is a string, apply formatDate function
            if (key.toLowerCase().includes('date') && typeof newItem[key] === 'string') {
              newItem[key] = formatDateTime(newItem[key]);
            }
            
            // If the value is an object, stringify it
            if (typeof newItem[key] === 'object' && newItem[key] !== null) {
              newItem[key] = newItem[key]?.name //JSON.stringify(newItem[key]);
            }
          });
          return newItem;
        });

        console.log(newData);

        setFormRecord(newData);

        setTotalCount(res.totalCount);
        setHasNextPage(res.hasNextPage);
        setHasPreviousPage(res.hasPreviousPage);
        setPageIndex(res.pageIndex);
      });
  }

  // const displayFormContractTitle = (data: any): string => {
  //   if (data?.contractTitle)
  //     return data.contractTitle;
  //   else if (data?.briefMaterialDescription)
  //     return data.briefMaterialDescription;
  //   else if (data?.briefDescriptionOfMaterials)
  //     return data.briefDescriptionOfMaterials;
  //   else if (data?.description)
  //     return data.description;
  //   else
  //     return "No title found";
  // }

  const getFormById = (formIDParam: string) => {
      preloader();

      const formTypesinfo = [
        {
          "name": "AdminAmendmentForm",
          "type": 1,
        },
        {
          "name": "CPNCRoutingSlipForm",
          "type": 3,
        },
        {
          "name": "RoutingSlipForTASContractsForm",
          "type": 6,
        },
        {
          "name": "ContractApprovalAADPForm",
          "type": 8,
        },
        {
          "name": "ContractApprovalForm",
          "type": 2,
        },
        {
          "name": "RoutingSlipForMaterialPurchaseForm",
          "type": 4,
        },
        {
          "name": "RoutingSlipForDeviationFromStandardTemplateForm",
          "type": 5,
        },
        {
          "name": "TUCNSavingsDeclarationSheetForm",
          "type": 7,
        }
      ]

      const seturl = formTypesinfo.find((item:any) => item.type === formTypeId);

      _$http('GET', seturl?.name+'/'+formIDParam).then(res => {
          closeAlert();
          const record: any = res.data.data;         

          if(record){
            const current_approval  = record.formApprovers.filter((x:any)=> x.formApproval !== null )
            console.log(current_approval[current_approval.length - 1])

            if(!!current_approval.length){
              setFormApprovers(current_approval[current_approval.length - 1]);
              setFormAlternativeEmail(record.alternateEmailAddress)
            }
            else{
              setFormApprovers(record.formApprovers[0]);
              setFormAlternativeEmail(record.alternateEmailAddress)
            }
                        
              setToggleCreateModal(true)
          }

      })
      .catch(error => {
          axiosError(error);
      })
  };

  const handleReassignEmailAddress = (el: any, option: any) => {
    setReassignEmailAddress(option.value);
  };

  const ReAssignForm = (formIDParam: string) => {

    if(!ReassignEmailAddress){
      tinyAlert('error', 'Email address is required!')
      return;
    }

    console.log(formIDParam, 'i see')

    if(!formIDParam){
      tinyAlert('error', 'Form Approval not available for this form!')
      return;
    }


    preloader();
    const data = {
      "formApproverId": formIDParam,
      "email": ReassignEmailAddress,
      "alternateEmailAddress": FormAlternativeEmail
    }

    _$http('POST', 'Form/ReAssignForm?formType='+formTypeId, data).then(res => {
        closeAlert();
        console.log(res)
        tinyAlert('success', res.data.message)
        setToggleCreateModal(false)

    })
    .catch(error => {
        axiosError(error);
    })
  };

  const updateActiveKeysArray = (e: any, x:string) => {
      let activelist:any = activeKeysArray;

      if(e.target.checked){
        activelist.push(x);
      }
      else{
        activelist = activelist.filter((item: any) => item !== x);
        console.log(activelist)
      }

      const uniqueArray = [...new Set(activelist)]
      setActiveKeysArray(uniqueArray);
  };

  return (
    <div className='w-full'> 
        <SettingsHeader />

        <div className='bg-white w-full shadow-sm rounded-br-lg rounded-bl-lg py-4 dark:bg-gray-800'>
          <div className='flex justify-between mb-3'>
            <div className='flex gap-2 px-4 w-2/5'>
              {/* search area */}
              <div className='relative w-full'>
                <input onChange={(e) => setSearchString(e.target.value)} type="text" name='search' className="border w-full pl-8 px-3 py-2 text-sm mr-1 dark:bg-gray-800 dark:text-gray-300" placeholder="Search Forms by Form ID, Contract Title & Initiator" />
                <FaMagnifyingGlass className='text-gray-400 absolute top-[10px] left-[10px]' />
              </div>

              {/* multi-filter btn area */}
              <button onClick={() => setToggleFilterModal(true)} type='button' className="h-[38px] border border-[#cbcbcb] px-3 text-sm dark:bg-gray-800 dark:text-gray-300 bg-gray-100 hover:bg-blue-100">
                <FaListUl />
              </button>

              {/* export to excel area */}
              <ExcelFile  filename={formTypeParam} element={
                <button className='h-[38px] text-gray-800 text-sm flex gap-2 border p-3 dark:bg-gray-800 dark:text-gray-300 border-[#cbcbcb] bg-gray-100 hover:bg-blue-100'>
                  <GrDocumentCsv /> <span className='text-xs'>Export</span>
                </button>
              }>
                  <ExcelSheet data={formRecord} name="Records">
                      {
                        activeKeysArray?.map((item: any, index: number) => (
                            <ExcelColumn key={index} label={ convertCamelCaseToWords(item) } value={item} />
                        ))
                      }
                  </ExcelSheet>
              </ExcelFile>
            </div>

            <div className='text-xs text-gray-400 pr-8 flex gap-8'>
              <select value={PageSize} onChange={(e) => setPageSize(Number(e.target.value))} id="entries" className='border p-1 text-xs dark:bg-gray-800 dark:text-gray-300'>
                <option value='3'>Show 3 entries</option>
                <option value='10'>Show 10 entries</option>
                <option value='25'>Show 25 entries</option>
                <option value='50'>Show 50 entries</option>
                <option value='100'>Show 100 entries</option>
                <option value='250'>Show 250 entries</option>
                <option value='500'>Show 500 entries</option>
                <option value='1000'>Show 1000 entries</option>
              </select>

              <span className='mt-3'>
                {formRecord?.length > 0 ? (PageSize * (PageIndex - 1)) + 1 : 0}-{(PageSize * (PageIndex - 1)) + formRecord?.length} of {TotalCount}
              </span>

              <span className='flex gap-5 mt-3'>
                <FaAngleLeft onClick={() => HasPreviousPage && setPage(PageIndex - 1)} className={`${HasPreviousPage && 'text-gray-900 hover-arrow-active dark:text-gray-400'}`} />
                <FaAngleRight onClick={() => HasNextPage && setPage(PageIndex + 1)} className={`${HasNextPage && 'text-gray-900 hover-arrow-active dark:text-gray-400'}`} />
              </span>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="table-auto min-w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className=" text-[12px] uppercase text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr className='hidden'>
                  <th scope="col" className="px-6 py-2"> Form ID  </th>

                  <th scope="col" className="px-6 py-2 min-w-[200px]">
                    { [4,5,6,7].includes(formTypeId) ? "Description" : "Contract Title" }
                  </th>

                  <th scope="col" className="px-6 py-2"> Initiator </th>

                  <th scope="col" className="px-6 py-2">
                    <div className='flex'>
                      <label htmlFor='status_options'>Status</label>
                      <select id="status_options" className='border-none bg-transparent p-1 text-xs text-gray-500 dark:bg-gray-800 dark:text-gray-300 w-[20px] h-5' onChange={(e) => setApprovalStatus(e.target.value)}>
                        <option value=''>All Status</option>
                        <option value='3'>Pending</option>
                        <option value='6'>Approved</option>
                        <option value='5'>Restart</option>
                        <option value='4'>Clarification</option>
                        <option value='7'>Rejected</option>
                      </select>
                    </div>
                  </th>

                  <th scope="col" className="px-6 py-2"> contractorsName </th>
                  <th scope="col" className="px-6 py-2"> contractValue </th>
                  <th scope="col" className="px-6 py-2"> currency </th>
                  <th scope="col" className="px-6 py-2"> amendmentValue </th>
                  <th scope="col" className="px-6 py-2"> agoraReference </th>
                  <th scope="col" className="px-6 py-2"> ddqStatus </th>
                  <th scope="col" className="px-6 py-2"> dqStatusDetails </th>
                  <th scope="col" className="px-6 py-2"> endDate </th>
                  <th scope="col" className="px-6 py-2"> newDate </th>
                  <th scope="col" className="px-6 py-2"> dprStatus </th>
                  <th scope="col" className="px-6 py-2"> procurementMethod </th>
                  <th scope="col" className="px-6 py-2"> marketIntelligence </th>
                  <th scope="col" className="px-6 py-2"> asset </th>
                  <th scope="col" className="px-6 py-2"> contractNumber </th>
                  <th scope="col" className="px-6 py-2"> prNumber </th>
                  <th scope="col" className="px-6 py-2"> nameOfBuyer </th>
                  <th scope="col" className="px-6 py-2"> amendmentNo </th>
                  <th scope="col" className="px-6 py-2"> alternateEmailAddress </th>
                  <th scope="col" className="px-6 py-2"> justification </th>
                  <th scope="col" className="px-6 py-2"> isHumanRight </th>
                  <th scope="col" className="px-6 py-2"> formType </th>
                  <th scope="col" className="px-6 py-2"> formNumber </th>
                  <th scope="col" className="px-6 py-2"> generatedFormNumber </th>
                  <th scope="col" className="px-6 py-2"> departmentId </th>
                  <th scope="col" className="px-6 py-2"> formApprovers </th>
                  <th scope="col" className="px-6 py-2"> referenceFormIds </th>
                  <th scope="col" className="px-6 py-2"> user </th>
                  <th scope="col" className="px-6 py-2"> parentFormId </th>
                  <th scope="col" className="px-6 py-2"> noOfRevisions </th>
                  <th scope="col" className="px-6 py-2"> isReviewedForm </th>

                  <th scope="col" className="px-6 py-2">
                    <div className='flex'>
                      <span>Department</span>
                      <select className='border-none bg-transparent p-1 text-xs text-gray-500 dark:bg-gray-800 dark:text-gray-300 w-[20px] h-5' onChange={(e) => setDepartmentID(e.target.value)}>
                        <option value=''>Department</option>
                        { department.map((item:any, i:any) => <option value={item.value} key={i}>{item.label}</option>) }
                      </select>
                    </div>
                  </th>

                  <th scope="col" className="px-6 py-2 w-[200px]"> Date Initiated </th>
                  <th scope="col" className="px-6 py-2 text-center"> Action </th>
                </tr>

                <tr>
                  {
                    activeKeysArray?.map((item: any, index: number) => {
                      return (
                        <th scope="col" className="px-6 pt-3" key={index}> { convertCamelCaseToWords(item) } </th>
                      )
                    })
                  }
                  
                  <th scope="col" className="px-6 pt-3 text-center"> Action </th>
                </tr>
              </thead>

              <tbody>                
                {
                  formRecord?.map((item: any, index: number) => {
                    return (
                      <tr key={index} className="bg-white border-t hover:bg-gray-5 text-[13px] dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        { 
                          activeKeysArray?.map((child_item: any, child_index: number) => {
                            return (
                              <td className="px-6 py-2" key={child_index}>
                                {
                                  typeof item?.[child_item] === 'object' ?  JSON.stringify(item?.[child_item]?.name) : item?.[child_item]
                                }
                              </td>
                            )
                          })
                        }    

                        <td className="px-6 py-2 flex gap-6 text-center">
                          <Link to={`/admin/forms/details/${formTypeParam}/${item.id}`} className="text-blue-600 flex gap-1">
                            <Eye /> <span>View</span>
                          </Link>
                          
                          <button disabled={item.currentStatus === 'CLOSED' || item.currentStatus === 'APPROVED'} className="text-orange-600 flex gap-1 disabled:border-transparent disabled:bg-white" onClick={() => getFormById(item.id)} >
                            <Repeat /> <span>Reassign</span>
                          </button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>

            {
              formRecord?.length < 1 &&
              <p className='text-center p-5 text-xs'>No record available...</p>
            }

            
            {
              toggleCreateModal &&
              <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
                <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
                  <div className='flex justify-between'>
                    <h2 className="text-lg">Reassign Form</h2>
                    <button onClick={() => setToggleCreateModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
                  </div>

                  <form>
                    <div className="modal-content my-4">

                      <div>
                        <LabelXS title="Approval Role" required />
                        <input type="text" name='name' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={FormApprovers.role.name} disabled />
                      </div>

                      <div className='mt-3'>
                        <LabelXS title="Current Approval" required />
                        <input type="text" name='name' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={FormApprovers.email} disabled />
                      </div>

                      <div className='mt-3'>
                        <LabelXS title="Reassign to:" required />
                        <Autocomplete
                            className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
                            filterOptions={filterOptions}
                            disableClearable={true}
                            onChange={(e, v) => handleReassignEmailAddress(e, v)}
                            id="AlternateEmailAddress"
                            options={tepng_users}
                            value={ReassignEmailAddress}
                            renderInput={(params) => (
                                <TextField
                                    placeholder="Select..."
                                    variant="standard"
                                    {...params}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    style={{
                                        fontFamily: "Nunito",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                    }}
                                >
                                    {getOptionLabel(option)}
                                </li>
                            )}
                        />
                      </div>
                    </div>

                    <div className='modal-footer flex justify-end'>
                      <button type="button" onClick={() => ReAssignForm(FormApprovers.formApproval?.formApproverId)} className="text-white w-full rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Reassign</button>
                    </div>

                  </form>
                </div>
              </div>
            }

            
            {
              toggleFilterModal &&
              <div className="fixed inset-0 flex items-center justify-center z-50" style={{background:'rgb(0 0 0 / 52%)'}}>
                  <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4 dark:bg-gray-600">
                      <div className='flex justify-between border-b py-2'>
                          <h2 className="text-sm">Custom Filter</h2>
                          <button onClick={() => setToggleFilterModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
                      </div>

                      <div className="modal-content my-4">
                        <div>
                          <p className='text-xs text-gray-600'>Filter By Date</p>
                        </div>
                        <div className='flex gap-4'>
                          <input type="date" name='ariba_closingdate' className="border px-3 py-2 text-xs mr-1 w-1/2" value={StartDate} onChange={(e:any) => setStartDate(e.target.value)} />
                          <input type="date" name='ariba_closingdate' className="border px-3 py-2 text-xs mr-1 w-1/2" value={EndDate} onChange={(e:any) => setEndDate(e.target.value)}  />
                        </div>   

                        <div>
                          <p className='text-xs text-gray-600 mt-3'>Filter By Status / Department</p>
                        </div>
                        <div className='flex gap-4'>
                          <select id="status_options" className='border px-3 py-2 text-xs mr-1 w-1/2' onChange={(e) => setApprovalStatus(e.target.value)}>
                            <option value=''>All Status</option>
                            <option value='3'>Pending</option>
                            <option value='6'>Approved</option>
                            <option value='5'>Restart</option>
                            <option value='4'>Clarification</option>
                            <option value='7'>Rejected</option>
                          </select>

                          <select className='border px-3 py-2 text-xs mr-1 w-1/2' onChange={(e) => setDepartmentID(e.target.value)}>
                            <option value=''>Department</option>
                            { department.map((item:any, i:any) => <option value={item.value} key={i}>{item.label}</option>) }
                          </select>

                        </div>     

                        <div className='border-b mb-3'>
                          <p className='text-xs text-gray-600 mt-5'>Table Fields</p>
                        </div>

                        <div className='h-[200px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4'>
                          { 
                            keysArray?.map((item: any, index: number) => {
                              return (
                                <label className='gap-4 flex uppercase text-xs' key={index}>
                                  {/* {index+1} */}
                                  <input type="checkbox" value={item} onChange={(e)=>updateActiveKeysArray(e, e.target.value)} />  {convertCamelCaseToWords(item)} 
                                </label>
                              )
                            })
                          }
                          
                          {/* checked={activeKeysArray.includes(item)}  */}
                          <div className='mt-10 flex'>&nbsp;</div> 
                        </div>   

                      </div>
                  </div>
              </div>
            } 
          </div>
        </div>
    </div>
  );
}

export default RaisedForm;

/**
 * path: /forms/raised forms/:ID
 * refactored: No
 */