import {
  ConstantProps,
  FormDetails,
  OptionProps,
  SavingSheetProps,
} from "../interfaces/forms.interface";
import { User } from "../interfaces/users.interface";
import {
  axiosget,
  axiospost,
  axiosput,
  axiosrequest,
  closeAlert,
  errorResponse,
  preloader,
  tinyAlert,
} from "../utils";
import { makePOST } from "./_config.services";

const api = {
  fetchCurrency: async () => {
    preloader();
    return await axiosget("FormConstants/Currencies")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "currency",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchDepartment: async () => {
    preloader();
    return await axiosget("Departments")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "department",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchDdqStatus: async () => {
    preloader();
    return await axiosget("FormConstants/DdqStatus")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "ddq_status",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchHumanRight: async () => {
    preloader();
    return await axiosget("FormConstants/HumanRights")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "human_right",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },


  fetchTGPCategory2: async () => {
    preloader();
    return await axiosget("FormConstants/TGPCategory")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "TGPCategory2",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchRoadMapNo: async () => {
    preloader();
    return await axiosget("FormConstants/RoadMapNo")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "RoadMapNo",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchServiceType: async () => {
    preloader();
    return await axiosget("FormConstants/SavingSheet")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: SavingSheetProps) => ({
            label: i.Type,
            value: i.Id,
            name: "ServiceType",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchNatureOfCostAvoidance: async () => {
    preloader();
    return await axiosget("FormConstants/NatureOfCostAvoidance")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "NatureOfCostAvoidance",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchAssets: async () => {
    preloader();
    return await axiosget("FormConstants/Assets")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({ label: i.name, value: i.id, name: "assets" })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchSavingTypes: async () => {
    preloader();
    return await axiosget("FormConstants/SavingType")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({ label: i.name, value: i.id, name: "saving_type" })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchIncoterms: async () => {
    preloader();
    return await axiosget("FormConstants/Incoterms")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({ label: i.name, value: i.id, name: "incoterms" })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchMaterialTypes: async () => {
    preloader();
    return await axiosget("FormConstants/MaterialType")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({ label: i.name, value: i.id, name: "material_type" })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchDPRStatus: async () => {
    preloader();
    return await axiosget("FormConstants/NUPRCStatuses")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "dprstatus",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchProcurementMethod: async () => {
    preloader();
    return await axiosget("FormConstants/ProcurementMethods")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "procurement_method",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchYesNoNotApplicable: async () => {
    preloader();
    return await axiosget("FormConstants/YesNoNotApplicable")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "yesno_notapplicable",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchTepngUsers: async () => {
    preloader();
    return await axiosget("User/CMDBUsers?size=300000")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map((i: User) => ({
          label: `<div>${i.email}</div><div style="position:relative; top:-5px"><small>${i.firstName} ${i.lastName}</small></div>`,
          value: i.email,
          name: "tepng_users",
        }));
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchVendors: async () => {
    preloader();
    return await axiosget("Vendor?Page=1&PageSize=300000")
      .then((res) => {
        const options: OptionProps[] = res.data.data.map((i: any) => ({
          label: `<div>${i.name}</div><div style="position:relative; top:-5px"><small>${i.address} ${i.city}</small></div>`,
          value: i.name,
          name: "tepng_users",
        }));
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchFormAttachmentTypes: async (params: number) => {
    preloader();
    return await axiosget(`FormAttachmentTypes/ByFormType?FormType=${params}`)
      .then((res) => {
        const options: OptionProps[] = res.data.data.map(
          (i: ConstantProps) => ({
            label: i.name,
            value: i.id,
            name: "attachment_types",
          })
        );
        return options;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchFormWorkFlow: async (params: number) => {
    preloader();
    return await axiosget(`Workflow/FormWorkflow?formType=${params}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },

  fetchFormDescription: async (params: string) => {
    preloader();
    return await axiosget("FormDescription")
      .then((res) => {
        const record: FormDetails = res.data.data.find(
          (item: FormDetails) =>
            item.name
              .toLocaleLowerCase()
              .replace(/ /g, "-")
              .replace(/\//g, "-") === params
        );
        return record;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },

  fetchAvailableForms: async () => {
    preloader();
    return await axiosget("FormDescription")
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchAvailableFormsCount: async () => {
    preloader();
    return await axiosget("FormDescription/UserPendingCount")
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },

  fetchFormById: async (url: string) => {
    preloader();
    return await axiosget(url)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },
  fetchApprovalSequence: async (url: string) => {
    // preloader();
    return await axiosget(url)
      .then((res) => {
        tinyAlert("success", "approval sequence updated!");
        return res.data.data;
      })
      .catch((err) => {
        tinyAlert("error", errorResponse(err));
        return err;
      })
      .finally(() => closeAlert());
  },
  downloadFile: async (params: string) => {
    preloader();
    return await axiosget(
      "Form/DownloadAttachment?DocumentId=" + params + "&FileDownloadType=2"
    )
      .then((res) => {
        console.log(res)
        var a = document.createElement("a");
        a.href = "data:image/png;base64," + res.data.data.base64String;
        a.download = res.data.data.fileName; // res.data.data.attachmentType.name+'-'+res.data.data.name
        a.click();
        return res;
      })
      .catch((err) => {
        tinyAlert("error", errorResponse(err));
        return err;
      })
      .finally(() => closeAlert());
  },
  downloadApprovalCommentFile: async (params: string) => {
    preloader();
    return await axiosget(
      "Form/DownloadApprovalCommentAttachment?DocumentId=" + params + "&FileDownloadType=2"
    )
      .then((res) => {
        console.log(res)
        var a = document.createElement("a");
        a.href = "data:image/png;base64," + res.data.data.base64String;
        a.download = res.data.data.fileName; // res.data.data.attachmentType.name+'-'+res.data.data.name
        a.click();
        return res;
      })
      .catch((err) => {
        tinyAlert("error", errorResponse(err));
        return err;
      })
      .finally(() => closeAlert());
  },
  previewFile: async (params: string) => {
    preloader();
    return await axiosget(
      "Form/DownloadAttachment?DocumentId=" + params + "&FileDownloadType=2"
    )
      .then((res) => {
        const base64Data = "data:application/pdf;base64," + res.data.data.base64String;

        return base64Data;
      })
      .catch((err) => {
        tinyAlert("error", errorResponse(err));
        return err;
      })
      .finally(() => closeAlert());
  },

  setForm: async (url: string, form_data: any) => {
    preloader();
    return await axiospost(url, form_data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })
      .catch((error:any) => {
          const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
          tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
          
      })
      .finally(() => closeAlert());
  },
  updateForm: async (url: string, form_data: any) => {
    preloader();
    return await axiosput(url, form_data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })
      // .catch((err) => err)
      .catch((error:any) => {
        const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
        tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
        
    })
      .finally(() => closeAlert());
  },
  submitDraft: async (url: string, form_data: any) => {
    preloader();
    return await axiosrequest("PUT", url, form_data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })
      // .catch((err) => err)
      .catch((error:any) => {
        const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
        tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
        
    })
      .finally(() => closeAlert());
  },
  axiosPOST: async (url: string, data: any) => {
    preloader();
    return await axiosrequest("POST", url, data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },

  approvalWorkflow: async (
    id: any,
    formName: string,
    workflowID: number,
    comment?: string,
    commentFiles?: any,
    extendWorkflow?: boolean,
    toRoleId?: string,
    toEmail?: string,
    FormApprovers?: any[]
  ) => {
    preloader();
    const form: any = new FormData();
    form.append("FormApproverId", id);
    form.append("Status", workflowID);
    form.append("Comments", comment);
    form.append("ExtendWorkflow", !!extendWorkflow);

    // form.append("ToRoleId", toRoleId);
    // form.append("ToEmail", toEmail);

    if(!!FormApprovers)
      form.append("FormApprovers", JSON.stringify(FormApprovers));

    for (let i = 0; i < commentFiles.length; i++) {
      form.append("CommentDocuments", commentFiles[i]);
    }

    return await makePOST(formName + "/UpdateFormApproval", form)
      // return await axiosrequest("POST", formName + "/UpdateFormApproval", data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return true;
      })
      .catch((err) => {
        tinyAlert("error", errorResponse(err));
        return false;
      })
      .finally(() => closeAlert());
  },

  fetchNotification: async (Page: number, PageSize: number, OrderBy: number) => {
    preloader();
    let url = `Notification?Page=${Page}&PageSize=${PageSize}&OrderBy=${OrderBy}`;

    return await axiosget(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());

  },
  updateNotification: async (url: string, form_data: any) => {
    preloader();
    return await axiosrequest("PUT", url, form_data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());
  },


  // required
  fetchFormHistory: async (formType: number, Page: number, PageSize: number, OrderBy: number, SearchString: string, ApprovalStatus: string, DepartmentID: string = '', StartDate: string = '', EndDate: string = '', isAdmin:boolean = false) => {
    preloader();
    let url = `Form/Submitted_History/${formType}?Page=${Page}&PageSize=${PageSize}&OrderBy=${OrderBy}&isAdmin=${isAdmin}`;

    if (!!SearchString)
      url += `&SearchString=${SearchString}`;

    if (!!ApprovalStatus)
      url += `&ApprovalStatus=${ApprovalStatus}`;

    if (!!DepartmentID)
      url += `&DepartmentID=${DepartmentID}`;

    if (!!StartDate && !!EndDate)
      url += `&StartDate=${StartDate}&EndDate=${EndDate}`;

    return await axiosget(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());

  },


  fetchPendingHistory: async (formType: number, Page: number, PageSize: number, OrderBy: number, SearchString: string, ApprovalStatus: string) => {
    preloader();
    let url = `FormApproval?formType=${formType}&Page=${Page}&PageSize=${PageSize}&OrderBy=${OrderBy}`;
    if (!!SearchString)
      url += `&SearchString=${SearchString}`;

    if (!!ApprovalStatus)
      url += `&ApprovalStatus=${ApprovalStatus}`;

    return await axiosget(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());

  },

  fetchPendingAll: async () => {
    preloader();
    let url = `FormApproval/Aggregate`;

    return await axiosget(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => err)
      .finally(() => closeAlert());

  }
};

export default api;
