import { makePOST, makePUT, makeREQUEST, tinyAlert, printError, } from "../_config.services";
// import {_$http} from "../utils";

class RoutingSlipForDeviationFromStandardTemplateFormService {
  // constructor() { }

  async setForm(data: any): Promise<any> {
    try{
      const form_approval_emails = data.form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }
      const form: any = new FormData();
      const input = data.formInputs;
      const select = data.formSelects;
      let others_description = "Others";

      form.append("Description", input.Description);
      form.append("NameOfBuyer", input.NameOfBuyer);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("Justification", input.Justification);
      form.append("Asset", select.assets.value);
      form.append("LinkedToCategory", select.linked_to_category.value);
      form.append("ValidatedByCATMAN", select.validated_by_catman.value);
      form.append("UpdatedByInsurance", select.template_reviewed_and_updated_by_insurance.value);
      form.append("UpdatedByLegal", select.template_reviewed_and_updated_by_legal.value);
      form.append("UpdatedByTax", select.template_reviewed_and_updated_by_tax.value);
      form.append("UpdatedByFinance", select.template_reviewed_and_updated_by_cost_control.value);
      form.append("UpdatedByCompliance", select.template_reviewed_and_updated_by_compliance.value);
      form.append("UpdatedByHSE", select.template_reviewed_and_updated_by_hse.value);
      form.append("OthersDescription", input.OthersDescription);
      form.append("DepartmentId", select.department.value);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));

      // processing-attachment-area
      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
        form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Current Template")
          form.append("CurrentTemplate", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Proposed Template")
          form.append("ProposedTemplate", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Transaction Related Document")
          form.append("TransactionRelatedDocument", data.uploadedFiles[i]);

        else {
          form.append("Others", data.uploadedFiles[i]);
          others_description = data.uploadedFilesAttachType[i];
        }
      }

      form.append("OthersDescription", others_description);

      return await makePOST('RoutingSlipForDeviationFromStandardTemplateForm/Initiator/Submit', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      });

    } catch (error) {
      tinyAlert("error", 'An error occurred!');
      console.error('Error:', error);
      
    }
  }

  async updateForm(data: any): Promise<any> {
    try{

      const form: any = new FormData();
      const input = data.formInputs;
      const select = data.formSelects;
      let others_description = "Others";

      form.append("Description", input.Description);
      form.append("NameOfBuyer", input.NameOfBuyer);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("Justification", input.Justification);
      form.append("Asset", select.assets.value);
      form.append("LinkedToCategory", select.linked_to_category.value);
      form.append("ValidatedByCATMAN", select.validated_by_catman.value);
      form.append("UpdatedByInsurance", select.template_reviewed_and_updated_by_insurance.value);
      form.append("UpdatedByLegal", select.template_reviewed_and_updated_by_legal.value);
      form.append("UpdatedByTax", select.template_reviewed_and_updated_by_tax.value);
      form.append("UpdatedByFinance", select.template_reviewed_and_updated_by_cost_control.value);
      form.append("UpdatedByCompliance", select.template_reviewed_and_updated_by_compliance.value);
      form.append("UpdatedByHSE", select.template_reviewed_and_updated_by_hse.value);
      form.append("DepartmentId", select.department.value);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));

      if (!!data.removedDocumentIds.length) {
          for (let i = 0; i < data.removedDocumentIds.length; i++) {
              form.append("RemovedDocumentIds", data.removedDocumentIds[i]);
          }
      }

      // processing-attachment-area
      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
        form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Current Template")
              form.append("CurrentTemplate", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Proposed Template")
          form.append("ProposedTemplate", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Transaction Related Document")
          form.append("TransactionRelatedDocument", data.uploadedFiles[i]);

        else {
          form.append("Others", data.uploadedFiles[i]);
            others_description = data.uploadedFilesAttachType[i];
        }
      }

      form.append("OthersDescription", others_description);

      return await makePUT(`RoutingSlipForDeviationFromStandardTemplateForm/Initiator/Update/${data.formIDQuery}`, form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } catch (error) {
      tinyAlert("error", printError(error));
      console.error('Error:', error);
    }
  }

  async updateApprovalForm(data: any): Promise<any> {
    try {
      return await makeREQUEST("POST", 'RoutingSlipForDeviationFromStandardTemplateForm/UpdateFormApproval', data)
          .then((res) => {
            tinyAlert("success", res.data.message);
            return res.data.data;
          });
    } catch(error) {
      tinyAlert("error", printError(error));
      console.error('Error:', error);
    }
  }
  async submitDraft(id: any): Promise<any> {
    try{
      const data = {
        "RoutingSlipForDeviationFromStandardTemplateFormId": id
      }
      return await makeREQUEST("PUT", 'RoutingSlipForDeviationFromStandardTemplateForm/Draft', data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }

  async replyToClarification(data: any): Promise<any> {
    try {

      const form: any = new FormData();
      const formApproverId = data.formApproverId;
      const clarificationComment = data.clarificationComment;

      form.append("FormApproverId", formApproverId);
      form.append("Comment", clarificationComment);
      let others_description = "Others";

      // processing-attachment-area
      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
        form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Current Template")
          form.append("CurrentTemplate", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Proposed Template")
          form.append("ProposedTemplate", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Transaction Related Document")
          form.append("TransactionRelatedDocument", data.uploadedFiles[i]);

        else {
          form.append("Others", data.uploadedFiles[i]);
          others_description = data.uploadedFilesAttachType[i];
        }
      }

      form.append("OthersDescription", others_description);

      return await makePOST('RoutingSlipForDeviationFromStandardTemplateForm/ReplyToClarification', form)
          .then((res) => {
            tinyAlert("success", res.data.message);
            return res.data.data;
          })

    } catch (error) {
      tinyAlert("error", 'An error occurred!');
      console.error('Error:', error);
    }
  }
  async reviewForm(data: any): Promise<any> {
    try{
      const form_approval_emails = data.form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }

      const form: any = new FormData();
      const input = data.formInputs;
      const select = data.formSelects;
      let others_description = "Others";

      form.append("PreviousRoutingSlipForDeviationFromStandardTemplateFormId", data.parentFormId);
      form.append("Description", input.Description);
      form.append("NameOfBuyer", input.NameOfBuyer);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("Justification", input.Justification);
      form.append("Asset", select.assets.value);
      form.append("LinkedToCategory", select.linked_to_category.value);
      form.append("ValidatedByCATMAN", select.validated_by_catman.value);
      form.append("UpdatedByInsurance", select.template_reviewed_and_updated_by_insurance.value);
      form.append("UpdatedByLegal", select.template_reviewed_and_updated_by_legal.value);
      form.append("UpdatedByTax", select.template_reviewed_and_updated_by_tax.value);
      form.append("UpdatedByFinance", select.template_reviewed_and_updated_by_cost_control.value);
      form.append("UpdatedByCompliance", select.template_reviewed_and_updated_by_compliance.value);
      form.append("UpdatedByHSE", select.template_reviewed_and_updated_by_hse.value);
      form.append("OthersDescription", input.OthersDescription);
      form.append("DepartmentId", select.department.value);
      form.append("IsDraft", false);
      form.append("FormApprovers", JSON.stringify(data.form_approval));

      // if (!!data.removedDocumentIds.length) {
      //   for (let i = 0; i < data.removedDocumentIds.length; i++) {
      //     form.append("RemovedDocumentIds", data.removedDocumentIds[i]);
      //   }
      // }

      // processing-attachment-area
      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
        form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);
        
        else if (data.uploadedFilesAttachType[i] === "Current Template")
          form.append("CurrentTemplate", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Proposed Template")
          form.append("ProposedTemplate", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Transaction Related Document")
          form.append("TransactionRelatedDocument", data.uploadedFiles[i]);

        else {
          form.append("Others", data.uploadedFiles[i]);
          others_description = data.uploadedFilesAttachType[i];
        }
      }

      form.append("OthersDescription", others_description);

      return await makePOST('RoutingSlipForDeviationFromStandardTemplateForm/Review', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } catch (error) {
      tinyAlert("error", 'An error occurred!');
      console.error('Error:', error);
      
    }
  }

  async fetchFormById(id: string): Promise<any> {
    try{
      return await makeREQUEST("GET", "RoutingSlipForDeviationFromStandardTemplateForm/" + id)
      .then((res) => {
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }

  async fetchApprovalSequence(data: any): Promise<any> {
    try{
      return await makeREQUEST("GET", `RoutingSlipForDeviationFromStandardTemplateForm/GetApprovalSequence?DepartmentId=${data.DepartmentId}`)
      .then((res) => {
        tinyAlert("success", "approval sequence updated!");
        return res.data.data;
      })
    } catch (error) {
      tinyAlert("error", printError(error));
      console.error('Error:', error);
    }
  }

  async deleteForm(id: string): Promise<any> {
    try{
      return await makeREQUEST("DELETE", "RoutingSlipForDeviationFromStandardTemplateForm/" + id)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }


};

const routingSlipForDeviationFromStandardTemplateFormService = new RoutingSlipForDeviationFromStandardTemplateFormService();
export default routingSlipForDeviationFromStandardTemplateFormService;